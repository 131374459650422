import { Link } from "react-router-dom";
import PathConstants from "../routes/pathConstants";
import { Container, Typography } from "@mui/material";
import NavBar from "./NavBar";
import Grid from '@mui/material/Grid'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import ClearIcon from '@mui/icons-material/Clear';

import { useState } from "react";

export default function Header() 
{
  return (
    <Container sx = {{backgroundColor: "gray"}}>
        <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center">
          <Typography>Get a NordVPN discount:</Typography>
            <Link to = "https://go.nordvpn.net/aff_c?offer_id=15&aff_id=110654&url_id=902" title="NordVPN affiliate link" target="_blank">
              <Typography sx ={{ml : 1}}>VPN</Typography>
            </Link>
            <Link to = "https://go.nordpass.io/aff_c?offer_id=488&aff_id=110654&url_id=9356" title="NordVPN affiliate link" target="_blank">
              <Typography sx ={{ml: 3}}>Password Manager</Typography>
            </Link>

        </Grid>
    </Container>
  );
}