import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Question from './pages/question'
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

const theme = createTheme
(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#c31df5',
      },
      secondary: {
        main: '#4FF51D',
      },
    },
  }
);

const root = ReactDOM.createRoot
(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
    <App />
    </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
