import React, { useState } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import { AppBar, Box, Container, Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavBar from "./components/NavBar"
import Question from "./pages/question"
import Home from "./pages/home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import Layout from "./components/Layout";
import Page404 from "./pages/error";

function App()
{
  // const [question, setQuestion] = useState(null);

  // fetch('http://localhost:4000/')
  // .then(response => response.json())
  // .then(data => {
  //   setQuestion(data.question)
  //   console.log(data)
  // })
  // .catch(error => console.error(error));
  
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <Page404 />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;

}

export default App;
