const PathConstants = {
    HOME: "/",
    QUESTION: "/question",
    HANGMAN: "/hangman",
    PRIVACY: "/privacy",
    BUGS: "/bugs",
    SUGGESTIONS: "/suggestions",
    AFFILIATE: "/affiliate"
}

export default PathConstants