import React from "react"
import PathConstants from "./pathConstants"

const Home = React.lazy(() => import("../pages/home"))
const Question = React.lazy(() => import("../pages/question"))
const Hangman = React.lazy(() => import("../pages/hangman"))
const Privacy = React.lazy(() => import("../pages/privacy"))
const BugFix = React.lazy(() => import("../pages/bugs"))
const Affiliate = React.lazy(() => import("../pages/affiliate"))

const routes = [
    { path: PathConstants.HOME, element: <Hangman /> },
    //{ path: PathConstants.QUESTION, element: <Question /> },
    //{ path: PathConstants.HANGMAN, element: <Hangman />},
    { path: PathConstants.PRIVACY, element: <Privacy />},
    {path: PathConstants.BUGS, element: <BugFix />},
    {path: PathConstants.AFFILIATE, element: <Affiliate/>},
]
export default routes