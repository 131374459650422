import { Link } from "react-router-dom";
import PathConstants from "../routes/pathConstants";
import NavBar from "./NavBar";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

export default function Footer() {
  return (
    <>
    <Grid  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{mt : 10}}>
      <Typography sx={{ml: 5}}>Copyright © 2024 History Buff - All Rights Reserved</Typography>
      <Link to="/privacy" style={{textDecoration: 'none', color : '#c31df5'}}>Privacy Policy</Link>
    </Grid>
    </>
  );
}